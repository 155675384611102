<template>
    <div>
        <relationTeamList :game_id="game_id"></relationTeamList>
    </div>
</template>
<script>

import relationTeamList from '@/components/relation/games/team/list.vue';

export default {
    components: {
        relationTeamList
    },
    data () {
        let self = (window.x = this);
        return {
            game_id: 6,
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>
